import React, { lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spinner } from '../components/spinner/Spinner'
import SkeletonAllRoutes from '../components/Skeleton/SkeletonAllRoutes'

const Dashboard = lazy(() => import("../components/Dashboard/Dashboard"))
const Private = lazy(() => import('../layout/Private'))
const Public = lazy(() => import('../layout/Public'))
const ChangePassword = lazy(() => import('../Pages/credentials/ChangePassword'))
const ForgotPassword = lazy(() => import('../Pages/credentials/ForgotPassword'))
const Login = lazy(() => import('../Pages/credentials/Login'))
const LogOut = lazy(() => import('../Pages/credentials/LogOut'))
const ResetPassword = lazy(() => import('../Pages/credentials/ResetPassword'))
const SuperAdminProfile = lazy(() => import('../Pages/superAdminProfile/SuperAdminProfile'))

const ClinicList = lazy(() => import('../Pages/clinics/ClinicList'))
const AssignTeamsList = lazy(() => import('../Pages/clinics/AssignTeamsList'))
const AddToGroupList = lazy(() => import('../Pages/clinics/AddToGroupList'))
// const AppointmentHistory = lazy(()=> import('../Pages/clinics/AppointmentHistory')) 
const AppointmentHistory = lazy(() => import('../Pages/Hospital/Patients/AppointmentHistory'))
const ClinicProfile = lazy(() => import('../Pages/clinics/ClinicProfile'))
const AddBranch = lazy(() => import('../Pages/clinics/clinicDetail/branch/AddBranch'))
const AddClinic = lazy(() => import('../Pages/clinics/AddClinic'))
const AddGroup = lazy(() => import('../Pages/clinics/AddGroup'))
const AddNewGroup = lazy(() => import('../Pages/clinics/AddNewGroup'))
const EditProviderProfile = lazy(() => import('../Pages/Hospital/Provider_Profile/EditProviderProfile'))
// const AppointmentDetails = lazy(()=> import('../Pages/clinics/AppointmentDetails')) 
const AppointmentDetails = lazy(() => import('../Pages/Hospital/Appointment/AppointmentDetails'))
const AddProvider = lazy(() => import('../Pages/Hospital/Provider_Profile/AddProvider'))
const ProgressNotes = lazy(() => import('../Pages/Hospital/Patients/ProgressNotes'))

const ClinicProfilePage = lazy(() => import('../Pages/clinics/clinicDetail/ClinicProfilePage'))
const ProviderProfile = lazy(() => import('../Pages/Hospital/Provider_Profile/ProviderProfile'))
const Hospital = lazy(() => import('../Pages/Hospital/Hospital'))
const Patients = lazy(() => import('../Pages/Hospital/Patients/Patients'))
const Provider = lazy(() => import('../Pages/Hospital/Provider_Profile/Provider'))
const AppointmentList = lazy(() => import('../Pages/Hospital/Appointment/AppointmentList'))
const PatientDetailsForm = lazy(() => import('../Pages/Hospital/Appointment/PatientDetailsForm'))
const AppointmentDetailForm = lazy(() => import('../Pages/Hospital/Appointment/AppointmentDetailForm'))

const CheckOut = lazy(() => import('../Pages/Hospital/CheckOut/CheckOut'))
const AddPatients = lazy(() => import('../Pages/Hospital/Patients/AddPatients'))
const EditPatientProfile = lazy(() => import('../Pages/Hospital/Patients/EditPatientProfile'))
const ClinicBranches = lazy(() => import('../Pages/clinics/clinicDetail/branch/ClinicBranches'))
const BankDetails = lazy(() => import('../Pages/clinics/clinicDetail/BankDetails'))
const PaymentGateway = lazy(() => import('../Pages/clinics/clinicDetail/paymentGateway/PaymentGateway'))
//patient all tab section
const PatientsProfile = lazy(() => import('../Pages/Hospital/Patients/PatientsProfile'))
const Personal_info_patient = lazy(() => import('../Pages/Hospital/Patients/Personal_info_patient'))
const Allergies = lazy(() => import('../Pages/Hospital/Patients/Allergies'))
const Attachments = lazy(() => import('../Pages/Hospital/Patients/Attachments'))
const Claims = lazy(() => import('../Pages/Hospital/Patients/Claims'))
const E_Prescription = lazy(() => import('../Pages/Hospital/Patients/E_Prescription'))
const FollowUps = lazy(() => import('../Pages/Hospital/Patients/FollowUps'))
const FormHistory = lazy(() => import('../Pages/Hospital/Patients/FormHistory'))
const FormsShared = lazy(() => import('../Pages/Hospital/Patients/FormsShared'))
const LabReport = lazy(() => import('../Pages/Hospital/Patients/LabReport'))
const Notes = lazy(() => import('../Pages/Hospital/Patients/Notes'))
const OnFileDocument = lazy(() => import('../Pages/Hospital/Patients/OnFileDocument'))
const PatientPayments = lazy(() => import('../Pages/Hospital/Patients/PatientPayments'))
// const ProgressNotess = lazy(()=> import('../Pages/Hospital/Patients/ProgressNotes')) 
const ProgressNotesList = lazy(() => import('../Pages/Hospital/Patients/ProgressNotesList'))
const RejectedForm = lazy(() => import('../Pages/Hospital/Patients/RejectedForm'))
const TreatmentPlan = lazy(() => import('../Pages/Hospital/Patients/TreatmentPlan'))
const VisitHistory = lazy(() => import('../Pages/Hospital/Patients/VisitHistory'))
const PaymentMode = lazy(() => import('../Pages/Hospital/Patients/paymentMode'))
const Personal_info = lazy(() => import('../Pages/Hospital/Provider_Profile/Personal_info'))
const Skills_Qualification = lazy(() => import('../Pages/Hospital/Provider_Profile/Skills_Qualification'))
const AppointmentTypes = lazy(() => import('../Pages/Hospital/Provider_Profile/AppointmentTypes'))
const Location = lazy(() => import('../Pages/Hospital/Provider_Profile/Location'))
const ClinicPolicies = lazy(() => import('../Pages/clinics/clinicDetail/ClinicPolicies'))
// const Primary = lazy(()=> import('../Pages/Hospital/Patients/Insurance/InsuranceType/Primary')) 
const Insurance = lazy(() => import('../Pages/Hospital/Patients/Insurance/Insurance'))
const Availability = lazy(() => import('../Pages/Hospital/Provider_Profile/Availability/Availability'))
const Unavailability = lazy(() => import('../Pages/Hospital/Provider_Profile/Availability/Unavailability'))
const AddAvailability = lazy(() => import('../Pages/Hospital/Provider_Profile/Availability/AddAvailability'))
const UpdateAvailability = lazy(() => import('../Pages/Hospital/Provider_Profile/Availability/UpdateAvailability'))
const ViewScheduler = lazy(() => import('../Pages/clinics/Scheduler/ViewScheduler'))

const ScheduleAppointmnet = lazy(() => import('../Pages/Hospital/Appointment/ScheduleAppointmnet'))
const ListSuperBills = lazy(() => import('../Pages/SuperBills/ListSuperBills'))
// const Codes = lazy(()=> import('../Pages/Codes/Codes')) 
const TemplateLibrary = lazy(() => import('../Pages/clinics/TemplateLIbrary/TemplateLibrary'))
const CreateForm = lazy(() => import('../Pages/clinics/TemplateLIbrary/CreateForm'))
const FormCategory = lazy(() => import('../Pages/clinics/clinicDetail/FormCategory'))
const ProgressNote = lazy(() => import('../Pages/clinics/ProgressNotes/ProgressNote'))
const ClaimsListing = lazy(() => import('../Pages/claims/ClaimsListing'))
const BillingTeamList = lazy(() => import('../Pages/BillingTeam/BillingTeamList'))
const AddBillingTeam = lazy(() => import('../Pages/BillingTeam/AddBillingTeam'))
const BillingTeamMemberList = lazy(() => import('../Pages/BillingTeam/BillingTeamMeamber/BillingTeamMemberList'))
const AddBillingMember = lazy(() => import('../Pages/BillingTeam/BillingTeamMeamber/AddBillingMember'))

// CheckOuts
const PatientCheckouts = lazy(() => import('../Pages/Hospital/CheckOut/PatientCheckouts'))
const PatientCheckoutForm = lazy(() => import('../Pages/Hospital/CheckOut/PatientCheckoutForm'))

const CheckedOutsList = lazy(() => import('../Pages/Hospital/CheckOut/CheckedOutsList'))
const NoShow = lazy(() => import('../Pages/Hospital/CheckOut/NoShow'))
const CreateFormProgressNote = lazy(() => import('../Pages/clinics/ProgressNotes/CreateFormProgressNote'))
const Setting = lazy(() => import('../Pages/Settings/Setting'))

// setting
const InsuranceS = lazy(() => import('../Pages/Settings/InsuranceS'))
const AppointmentType = lazy(() => import('../Pages/clinics/AppointmentTypes/AppointmentType'))
const AddInsurance = lazy(() => import('../Pages/Settings/AddInsurance'))
const EditInsurance = lazy(() => import('../Pages/Settings/EditInsurance'))
const CPTList = lazy(() => import('../Pages/Settings/InsuranceCodes/CPT/CPTList'))
const ICDList = lazy(() => import('../Pages/Settings/InsuranceCodes/ICD/ICDList'))
const ModifierList = lazy(() => import('../Pages/Settings/InsuranceCodes/Modifier/ModifierList'))
const AddCPT = lazy(() => import('../Pages/Settings/InsuranceCodes/CPT/AddCPT'))
const EditCPT = lazy(() => import('../Pages/Settings/InsuranceCodes/CPT/EditCPT'))
const AddICD = lazy(() => import('../Pages/Settings/InsuranceCodes/ICD/AddICD'))
const EditICD = lazy(() => import('../Pages/Settings/InsuranceCodes/ICD/EditICD'))
const AddModifier = lazy(() => import('../Pages/Settings/InsuranceCodes/Modifier/AddModifier'))
const EditModifier = lazy(() => import('../Pages/Settings/InsuranceCodes/Modifier/EditModifier'))
const GenerateSuperBill = lazy(() => import('../Pages/Hospital/CheckOut/GenerateSuperBill'))
const TreatmentPlans = lazy(() => import('../Pages/clinics/TreatmentPlan/TreatmentPlans'))
const CreateTreatmentForm = lazy(() => import('../Pages/clinics/TreatmentPlan/CreateTreatmentForm'))
const TreatmentplansView = lazy(() => import('../Pages/Hospital/Patients/TreatmentplansView'))
const EditSuperBill = lazy(() => import('../Pages/SuperBills/EditSuperBill'))
const Payment = lazy(() => import('../Pages/Payment'))
const Skills = lazy(() => import('../Pages/Settings/skills'))
const AuthGuard = lazy(() => import('./AuthGuard'))
const RoleandPermissions = lazy(() => import("../Pages/RoleandPermissions"))
const PaymentList = lazy(() => import('../Pages/Payment/PaymentList'))
const EditPayment = lazy(() => import('../Pages/Payment/EditPayment'))
const UpdateRoleAndPermission = lazy(() => import('../Pages/RoleandPermissions/UpdateRoleAndPermission'))
const Reports = lazy(() => import('../Pages/Reports'))
const TotalAR = lazy(() => import('../Pages/Reports/EpiphanyAging/TotalAR'))
const AgingDOS = lazy(() => import('../Pages/Reports/EpiphanyAging/AgingDOS'))
const SummaryDOS = lazy(() => import('../Pages/Reports/EpiphanyAging/SummaryDOS'))
const SummarySubmission = lazy(() => import('../Pages/Reports/EpiphanyAging/SummarySubmission'))
const AgingSubmission = lazy(() => import('../Pages/Reports/EpiphanyAging/AgingSubmission'))
const AllReports = lazy(() => import('../Pages/Reports/AllReports'))
const EpiphanyCharges = lazy(() => import('../Pages/Reports/EpiphanyCharges/EpiphanyCharges'))
const EpiphanyAging = lazy(() => import('../Pages/Reports/EpiphanyAging/EpiphanyAging'))
const EpiphanyDenial = lazy(() => import('../Pages/Reports/EpiphanyDenial/EpiphanyDenial'))
const Epiphanyinsurance = lazy(() => import('../Pages/Reports/Epiphanyinsurance/Epiphanyinsurance'))
const Patientpayment = lazy(() => import('../Pages/Reports/patientpayment/patientpayment'))
const Announcements = lazy(() => import('../Pages/Announcements/Announcements'))

const NoteTypeList = lazy(() => import('../Pages/clinics/NoteType/NoteTypeList'))
const Page404 = lazy(() => import('../Pages/page404/Page404'))
const AddAnnouncement = lazy(() => import('../Pages/Announcements/AddAnnouncement'))
const EditAnnouncement = lazy(() => import('../Pages/Announcements/EditAnnouncement'))
const ClaimsHistory = lazy(() => import('../Pages/claims/ClaimsHistory'))
const HelpDeskList = lazy(() => import('../Pages/HelpDesk/HelpDeskList'))
const AddHelpDesk = lazy(() => import('../Pages/HelpDesk/AddHelpDesk'))
const Notifications = lazy(() => import('../components/Header/Notifications'))
const DirectGenerateSuperBill = lazy(() => import('../Pages/SuperBills/DirectGenerateSuperBill'))
const WithOutAppointmentSuperBill = lazy(() => import('../Pages/SuperBills/WithOutAppointmentSuperBill'))
const ProblemLog = lazy(() => import('../Pages/ProblemLog/ProblemLog'))
const CreateProblemLog = lazy(() => import('../Pages/ProblemLog/CreateProblemLog'))
const CreateNewPassword = lazy(() => import("../Pages/credentials/CreateNewPassword"))
const Credentialing = lazy(() => import('../Pages/Credentialing/Credentialing'))
const ProviderInfoMainPage = lazy(() => import('../Pages/Credentialing/ProviderInfo/ProviderInfoMainPage'))
const PeroviderInfo = lazy(() => import('../Pages/Credentialing/ProviderInfo/ProviderInfo'))
const Documents = lazy(() => import('../Pages/Credentialing/ProviderInfo/Documents'))
const Credentialings = lazy(() => import("../Pages/Credentialing/ProviderInfo/Credentialing"))
const ServiceOrder = lazy(() => import('../Pages/Credentialing/ProviderInfo/ServiceOrder'))
const CptPaymentSettlement = lazy(() => import('../Pages/Credentialing/ProviderInfo/CptPaymentSettlement'))
const CPTLinkedList = lazy(() => import('../Pages/Credentialing/ProviderInfo/CPTLinkedList'))
const ClaimsHisrtoryMainPage = lazy(() => import('../Pages/claims/ClaimsHisrtoryMainPage'))
const PaymentDetails = lazy(() => import('../Pages/claims/PaymentDetails'))
const PaymentReconcile = lazy(() => import('../Pages/Payment/PaymentReconcile'))
const PaymentReconcileViewPage = lazy(() => import('../Pages/Payment/PaymentReconcileViewPage'))
const PatientReport = lazy(() => import('../Pages/Reports/patientpayment/PatientReport'))
const FollowUpClaims = lazy(() => import('../Pages/FollowUpClaims'))
const TroubleshootMfa = lazy(() => import('../Pages/credentials/TroubleshootMfa'))
const RemainBalance = lazy(() => import('../Pages/Reports/RemainBalance/RemainBalance'))
const ErrorBoundary = lazy(() => import("../components/Error/ErrorBoundary"))
const ChooseClinic = lazy(() => import('../Pages/credentials/ChooseClinic'))



function AllRoutes() {
    const { user: currentUser } = useSelector((state) => state?.auth)
    // console.log(currentUser, "currentUser")
    return (
        <><Suspense fallback={<SkeletonAllRoutes />}>
            <Routes> {!currentUser?.token ? (
                <Route path="/" element={<ErrorBoundary fallback="Error Ouccer"><Public /></ErrorBoundary>} >
                    <Route index element={<ErrorBoundary fallback="Error Ouccer"> <Login /></ErrorBoundary>} />
                    <Route path='login' element={<ErrorBoundary fallback="Error Ouccer"> <Login /></ErrorBoundary>} />
                    <Route path='forgot-password' element={<ErrorBoundary fallback="Error Ouccer"> <ForgotPassword /></ErrorBoundary>} />
                    <Route path='trouble-shoot-mfa' element={<ErrorBoundary fallback="Error Ouccer"> <TroubleshootMfa /> </ErrorBoundary>} />
                    <Route path='reset-password/:id' element={<ErrorBoundary fallback="Error Ouccer"> <ResetPassword /> </ErrorBoundary>} />
                    <Route path='create-new-password/:id' element={<ErrorBoundary fallback="Error Ouccer"> <CreateNewPassword /> </ErrorBoundary>} />
                </Route>)
                : currentUser?.token && !currentUser?.selectedClinic_id ? (
                    <Route path="/" element={<ErrorBoundary fallback="Error Ouccer"> <Private /></ErrorBoundary>}>
                        <Route index path='/' element={<ErrorBoundary fallback="Error Ouccer"> <Navigate replace to={"/select-clinic"} /> </ErrorBoundary>} />
                        <Route path='select-clinic' element={<ErrorBoundary fallback="Error Ouccer"> <ChooseClinic /> </ErrorBoundary>} />
                    </Route>
                ) : (
                    <Route path="/" element={<ErrorBoundary fallback="Error Ouccer"> <Private /></ErrorBoundary>}>
                        {/* Dashboard */}
                        <Route index path='/' element={<ErrorBoundary fallback="Error Ouccer"> <Navigate replace to={"/dashboard"} /> </ErrorBoundary>} />
                        <Route path='dashboard' element={<ErrorBoundary fallback="Error Ouccer"> <Dashboard /> </ErrorBoundary>} />
                        <Route path='notification' element={<ErrorBoundary fallback="Error Ouccer"> <Notifications /> </ErrorBoundary>} />

                        <Route path='profile' element={<ErrorBoundary fallback="Error Ouccer"> <SuperAdminProfile /> </ErrorBoundary>} />
                        <Route path='change-password' element={<ErrorBoundary fallback="Error Ouccer"> <ChangePassword /> </ErrorBoundary>} />
                        <Route path='logout' element={<ErrorBoundary fallback="Error Ouccer"> <LogOut /> </ErrorBoundary>} />
                        {/* start clinic /provider/patient/appointment/checkout */}

                        {/* Clinic */}
                        <Route path='clinic' element={<AuthGuard page="CLINIC" read={true} > <ErrorBoundary fallback="Error Ouccer"><ClinicList /></ErrorBoundary> </AuthGuard >} />
                        <Route path='clinic/scheduler' element={<ErrorBoundary fallback="Error Ouccer"><ViewScheduler /></ErrorBoundary>} />

                        <Route path='clinic/:id' element={<ErrorBoundary fallback="Error Ouccer"> <Hospital /></ErrorBoundary>} >
                            <Route path='Patients' element={<AuthGuard page='PATIENT' read={true} ><ErrorBoundary fallback="Error Ouccer">  <Patients /> </ErrorBoundary></AuthGuard>} />
                            <Route path='provider' element={<AuthGuard page='PROVIDER' read={true} ><ErrorBoundary fallback="Error Ouccer">  <Provider /> </ErrorBoundary> </AuthGuard>} />
                            <Route path='appointment' element={<AuthGuard page='APPOINTMENT' read={true} ><ErrorBoundary fallback="Error Ouccer">  <AppointmentList /> </ErrorBoundary> </AuthGuard>} />

                            {/* Check Out */}
                            <Route path='checkout' element={<AuthGuard page='CHECKOUT' read={true} ><ErrorBoundary fallback="Error Ouccer">  <CheckOut /> </ErrorBoundary> </AuthGuard>} >
                                <Route exact index element={<AuthGuard page='CHECKOUT' read={true} ><ErrorBoundary fallback="Error Ouccer">  <PatientCheckouts /> </ErrorBoundary> </AuthGuard>} />
                                <Route path='checked-outs' element={<AuthGuard page='CHECKOUT' read={true} ><ErrorBoundary fallback="Error Ouccer"> <CheckedOutsList /> </ErrorBoundary> </AuthGuard>} />
                                <Route path='no-show' element={<AuthGuard page='CHECKOUT' read={true} ><ErrorBoundary fallback="Error Ouccer"> <NoShow /> </ErrorBoundary> </AuthGuard>} />
                            </Route>
                            {/* Check OUt End */}
                        </Route>
                        <Route path='clinic/:id/checkout/patient-checkouts/:appointmentId' element={<ErrorBoundary fallback="Error Ouccer"> <PatientCheckoutForm /> </ErrorBoundary>} />
                        <Route path='clinic/:id/checkout/checked-outs/generate-super-bill' element={<ErrorBoundary fallback="Error Ouccer"> <GenerateSuperBill /> </ErrorBoundary>} />
                        <Route path='clinic/:id/checkout/patient-checkouts' element={<ErrorBoundary fallback="Error Ouccer"> <PatientCheckoutForm /> </ErrorBoundary>} />


                        <Route path='clinic/:id/provider/add-provider' element={<ErrorBoundary fallback="Error Ouccer"> <AddProvider /> </ErrorBoundary>} />
                        <Route path='clinic/:id/provider/edit-provider/:patientId' element={<ErrorBoundary fallback="Error Ouccer"> <EditProviderProfile /> </ErrorBoundary>} />
                        <Route path='clinic/:id/provider/profile/:patientId' element={<ErrorBoundary fallback="Error Ouccer"> <ProviderProfile /> </ErrorBoundary>} >


                            <Route index element={<ErrorBoundary fallback="Error Ouccer"> <Personal_info /> </ErrorBoundary>} />
                            <Route path='skills-qualification' element={<ErrorBoundary fallback="Error Ouccer"> <Skills_Qualification /> </ErrorBoundary>} />
                            <Route path='appointment-types' element={<ErrorBoundary fallback="Error Ouccer"> <AppointmentTypes /> </ErrorBoundary>} />
                            <Route path='location' element={<ErrorBoundary fallback="Error Ouccer"> <Location /> </ErrorBoundary>} />
                            <Route path='availability' element={<ErrorBoundary fallback="Error Ouccer"> <Availability /> </ErrorBoundary>} />
                            <Route path='unavailability' element={<ErrorBoundary fallback="Error Ouccer"> <Unavailability /> </ErrorBoundary>} />
                        </Route>
                        <Route path='clinic/:id/provider/profile/:patientId/add-availability' element={<ErrorBoundary fallback="Error Ouccer"> <AddAvailability /> </ErrorBoundary>} />
                        <Route path='clinic/:id/provider/profile/:patientId/edit-availability' element={<ErrorBoundary fallback="Error Ouccer"> <UpdateAvailability /> </ErrorBoundary>} />


                        {/* end proveder */}

                        {/* Patient Rout's */}
                        <Route path='clinic/:id/patient/add-patients' element={<ErrorBoundary fallback="Error Ouccer"> <AddPatients /> </ErrorBoundary>} />
                        <Route path='clinic/:id/patient/edit-patient/:patientId' element={<ErrorBoundary fallback="Error Ouccer"> <EditPatientProfile /> </ErrorBoundary>} />
                        <Route path='clinic/:id/patient/profile/:patientId' element={<ErrorBoundary fallback="Error Ouccer"> <PatientsProfile /> </ErrorBoundary>} >
                            <Route index element={<ErrorBoundary fallback="Error Ouccer"> <Personal_info_patient /> </ErrorBoundary>} />
                            <Route path='allergies' element={<ErrorBoundary fallback="Error Ouccer"> <Allergies /> </ErrorBoundary>} />
                            <Route path='payment-mode' element={<ErrorBoundary fallback="Error Ouccer"> <PaymentMode /> </ErrorBoundary>} />
                            <Route path='attachments' element={<ErrorBoundary fallback="Error Ouccer"> <Attachments /> </ErrorBoundary>} />
                            <Route path='claims' element={<ErrorBoundary fallback="Error Ouccer"> <Claims /> </ErrorBoundary>} />
                            <Route path='e-prescription' element={<ErrorBoundary fallback="Error Ouccer"> <E_Prescription /> </ErrorBoundary>} />
                            <Route path='follow-ups' element={<ErrorBoundary fallback="Error Ouccer"> <FollowUps /> </ErrorBoundary>} />
                            <Route path='form-history' element={<ErrorBoundary fallback="Error Ouccer"> <FormHistory /> </ErrorBoundary>} />
                            <Route path='forms-shared' element={<ErrorBoundary fallback="Error Ouccer"> <FormsShared /> </ErrorBoundary>} />
                            <Route path='insurance' element={<ErrorBoundary fallback="Error Ouccer"> <Insurance /> </ErrorBoundary>} />
                            <Route path='lab-report' element={<ErrorBoundary fallback="Error Ouccer"> <LabReport /> </ErrorBoundary>} />
                            <Route path='notes' element={<ErrorBoundary fallback="Error Ouccer"> <Notes /> </ErrorBoundary>} />
                            <Route path='on-file-document' element={<ErrorBoundary fallback="Error Ouccer"> <OnFileDocument /> </ErrorBoundary>} />
                            <Route path='patient-payment' element={<ErrorBoundary fallback="Error Ouccer"> <PatientPayments /> </ErrorBoundary>} />
                            <Route path='progess-notes' element={<ErrorBoundary fallback="Error Ouccer"> <ProgressNotesList /> </ErrorBoundary>} />
                            <Route path='rejected-form' element={<ErrorBoundary fallback="Error Ouccer"> <RejectedForm /> </ErrorBoundary>} />
                            <Route path='treatment-plan' element={<ErrorBoundary fallback="Error Ouccer"> <TreatmentPlan /> </ErrorBoundary>} />
                            <Route path='visit-history' element={<ErrorBoundary fallback="Error Ouccer"> <VisitHistory /> </ErrorBoundary>} />
                        </Route>

                        {/* Patient End */}

                        <Route path='clinic/assign-teams' element={<ErrorBoundary fallback="Error Ouccer"> <AssignTeamsList /> </ErrorBoundary>} />
                        <Route path='clinic/add-to-group' element={<ErrorBoundary fallback="Error Ouccer"> <AddToGroupList /> </ErrorBoundary>} />
                        <Route path='clinic/appointment-details/:id' element={<ErrorBoundary fallback="Error Ouccer"> <AppointmentDetails /> </ErrorBoundary>} >
                            <Route index element={<ErrorBoundary fallback="Error Ouccer"> <AppointmentDetailForm /> </ErrorBoundary>} />
                            <Route path='patient-details' element={<ErrorBoundary fallback="Error Ouccer"> <PatientDetailsForm /> </ErrorBoundary>} />
                        </Route>

                        <Route path="clinic/:id/appointment/schedule-appointment" element={<ErrorBoundary fallback="Error Ouccer"> <ScheduleAppointmnet /> </ErrorBoundary>} />
                        <Route path='clinic/patient/profile/Appointment-history' element={<ErrorBoundary fallback="Error Ouccer"> <AppointmentHistory /> </ErrorBoundary>} />
                        <Route path='clinic/patient/profile/progress-note/:id' element={<ErrorBoundary fallback="Error Ouccer"> <ProgressNotes /> </ErrorBoundary>} />
                        <Route path='clinic/patient/profile/treatment-plan/:id' element={<ErrorBoundary fallback="Error Ouccer"> <TreatmentplansView /> </ErrorBoundary>} />
                        {/* <Route path='clinic/patient/add-patients' element={<ErrorBoundary fallback="Error Ouccer"> <AddPatients /> </ErrorBoundary>} />
                    <Route path='clinic/patient/edit-patient' element={<ErrorBoundary fallback="Error Ouccer"> <EditPatientProfile />} /> </ErrorBoundary> */}

                        <Route path='clinic/clinic-profile/:id' element={<ErrorBoundary fallback="Error Ouccer"> <ClinicProfile /></ErrorBoundary>}>
                            <Route exact index element={<ErrorBoundary fallback="Error Ouccer"> <ClinicProfilePage /> </ErrorBoundary>} />
                            <Route exact path='clinic-branches' element={<ErrorBoundary fallback="Error Ouccer"> <ClinicBranches /> </ErrorBoundary>} />
                            <Route exact path='clinic-bank-details' element={<ErrorBoundary fallback="Error Ouccer"> <BankDetails /> </ErrorBoundary>} />
                            <Route exact path='clinic-payment-gateway' element={<ErrorBoundary fallback="Error Ouccer"> <PaymentGateway /> </ErrorBoundary>} />
                            <Route exact path='clinic-policies' element={<ErrorBoundary fallback="Error Ouccer"> <ClinicPolicies /> </ErrorBoundary>} />
                            <Route exact path='form-category' element={<ErrorBoundary fallback="Error Ouccer"> <FormCategory /> </ErrorBoundary>} />
                            <Route exact path='appointment-types' element={<ErrorBoundary fallback="Error Ouccer"> <AppointmentType /> </ErrorBoundary>} />
                            <Route exact path='note-type' element={<ErrorBoundary fallback="Error Ouccer"> <NoteTypeList /> </ErrorBoundary>} />


                            <Route path='progress-notes' element={<ErrorBoundary fallback="Error Ouccer"> <ProgressNote /> </ErrorBoundary>} />
                            <Route path='template-library' element={<ErrorBoundary fallback="Error Ouccer"> <TemplateLibrary /> </ErrorBoundary>} />
                            <Route path='treatment-plan' element={<ErrorBoundary fallback="Error Ouccer"> <TreatmentPlans /> </ErrorBoundary>} />

                        </Route>

                        <Route path='clinic/Profile-clinic/add-branch' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <AddBranch /></ErrorBoundary></AuthGuard>} />
                        <Route path='clinic/add-clinic' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <AddClinic /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='clinic/add-group' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <AddGroup /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='clinic/add-group/add-new-group/:id' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <AddNewGroup /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='clinic/Profile-clinic/template-library/create-form' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <CreateForm /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='clinic/Profile-clinic/progress-notes/create-form' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <CreateFormProgressNote /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='clinic/Profile-clinic/treatment-plan/create-form' element={<AuthGuard page='CLINIC' write={true} ><ErrorBoundary fallback="Error Ouccer"> <CreateTreatmentForm /> </ErrorBoundary> </AuthGuard>} />

                        {/* Credentialing */}

                        <Route path="/credentialing" element={<ErrorBoundary fallback="Error Ouccer"><Credentialing /> </ErrorBoundary>} />
                        <Route path="/credentialing/:clinicId/provider-profile/:prviderID/credentials/:insuranceID" element={<ErrorBoundary fallback="Error Ouccer"><CPTLinkedList /> </ErrorBoundary>} />

                        <Route path="/credentialing/:clinicId/provider-profile/:prviderID" element={<ErrorBoundary fallback="Error Ouccer"><ProviderInfoMainPage /></ErrorBoundary>}>
                            <Route index element={<ErrorBoundary fallback="Error Ouccer"><PeroviderInfo /> </ErrorBoundary>} />
                            <Route path='documents' element={<ErrorBoundary fallback="Error Ouccer"><Documents /> </ErrorBoundary>} />
                            <Route path='compliance' element={<ErrorBoundary fallback="Error Ouccer"><ServiceOrder /> </ErrorBoundary>} />
                            <Route path='credentials' element={<ErrorBoundary fallback="Error Ouccer"><Credentialings /> </ErrorBoundary>} />
                            <Route path='payment-settlement' element={<ErrorBoundary fallback="Error Ouccer"><CptPaymentSettlement /> </ErrorBoundary>} />

                        </Route>

                        {/* Belling team */}
                        <Route path='billing-team' element={<AuthGuard page='BILLINGTEAM' read={true} > <ErrorBoundary fallback="Error Ouccer">  <BillingTeamList /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='billing-team/create-team' element={<AuthGuard page='BILLINGTEAM' write={true} > <ErrorBoundary fallback="Error Ouccer"> <AddBillingTeam /> </ErrorBoundary> </AuthGuard>} />

                        {/* Billing Member Team */}
                        <Route path='billing-team/:teamId' element={<AuthGuard page='BILLINGTEAM' read={true} > <ErrorBoundary fallback="Error Ouccer"> <BillingTeamMemberList /> </ErrorBoundary> </AuthGuard>} />
                        <Route path='billing-team/add-member' element={<AuthGuard page='BILLINGTEAM' write={true} > <ErrorBoundary fallback="Error Ouccer"> <AddBillingMember /> </ErrorBoundary> </AuthGuard>} />


                        {/* super bill routes */}
                        <Route path='super-bill' element={<AuthGuard page="SUPERBILL" read={true} ><ErrorBoundary fallback="Error Ouccer"><ListSuperBills /> </ErrorBoundary></AuthGuard>} />
                        <Route path='super-bill/:id' element={<AuthGuard page="SUPERBILL" read={true} > <ErrorBoundary fallback="Error Ouccer"> <EditSuperBill /> </ErrorBoundary></AuthGuard>} />
                        <Route path='super-bill/edit' element={<AuthGuard page="SUPERBILL" write={true} > <ErrorBoundary fallback="Error Ouccer"> <GenerateSuperBill /> </ErrorBoundary></AuthGuard>} />
                        <Route path='super-bill/generate-super-bill' element={<AuthGuard page="SUPERBILL" write={true} > <ErrorBoundary fallback="Error Ouccer"> <DirectGenerateSuperBill /> </ErrorBoundary></AuthGuard>} />

                        <Route path='super-bill/generate-without-appointment-super-bill' element={<AuthGuard page="SUPERBILL" write={true} > <ErrorBoundary fallback="Error Ouccer"> <WithOutAppointmentSuperBill /> </ErrorBoundary></AuthGuard>} />

                        {/* Claims */}
                        <Route path='claims' element={<AuthGuard page="CLAIMS" read={true} > <ErrorBoundary fallback="Error Ouccer"> <ClaimsListing /> </ErrorBoundary></AuthGuard>} />
                        <Route path='claims/claim-history/:id' element={<AuthGuard page="CLAIMS" write={true} > <ErrorBoundary fallback="Error Ouccer"> <ClaimsHisrtoryMainPage /> </ErrorBoundary></AuthGuard>} >
                            <Route index element={<AuthGuard page="CLAIMS" write={true} > <ErrorBoundary fallback="Error Ouccer"> <ClaimsHistory /> </ErrorBoundary></AuthGuard>} />
                            <Route path='payment-details' element={<AuthGuard page="CLAIMS" write={true} > <ErrorBoundary fallback="Error Ouccer"> <PaymentDetails /> </ErrorBoundary></AuthGuard>} />
                        </Route>

                        {/* Follow-Up */}
                        <Route path='follow-up' element={<AuthGuard page="CLAIMS" read={true} > <ErrorBoundary fallback="Error Ouccer"> <FollowUpClaims /> </ErrorBoundary></AuthGuard>} />

                        {/* Reports */}

                        <Route path='reports' element={<AuthGuard page="REPORTS" read={true} > <ErrorBoundary fallback="Error Ouccer"> <AllReports /> </ErrorBoundary></AuthGuard>} >
                            <Route index path='payment-report' element={<ErrorBoundary fallback="Error Ouccer"> <PatientReport /> </ErrorBoundary>} />
                            <Route path='charges' element={<ErrorBoundary fallback="Error Ouccer"> <EpiphanyCharges /> </ErrorBoundary>} />
                            <Route path='aging' element={<ErrorBoundary fallback="Error Ouccer"> <EpiphanyAging /> </ErrorBoundary>} >
                                <Route index path='totel-ar' element={<ErrorBoundary fallback="Error Ouccer"> <TotalAR /> </ErrorBoundary>} />
                                <Route path='aging-dos' index element={<ErrorBoundary fallback="Error Ouccer"> <AgingDOS /> </ErrorBoundary>} />
                                <Route path='summary-dos' index element={<ErrorBoundary fallback="Error Ouccer"> <SummaryDOS /> </ErrorBoundary>} />
                                <Route path='aging-submission' index element={<ErrorBoundary fallback="Error Ouccer"> <AgingSubmission /> </ErrorBoundary>} />
                                <Route path='summary-submission' index element={<ErrorBoundary fallback="Error Ouccer"> <SummarySubmission /> </ErrorBoundary>} />
                            </Route>
                            <Route index path='denial' element={<ErrorBoundary fallback="Error Ouccer"> <EpiphanyDenial /> </ErrorBoundary>} />
                            <Route index path='insurance' element={<ErrorBoundary fallback="Error Ouccer"> <Epiphanyinsurance /> </ErrorBoundary>} />
                            <Route index path='patient-payment-records' element={<ErrorBoundary fallback="Error Ouccer"> <Patientpayment /> </ErrorBoundary>} />
                            <Route index path='patient-balance-report' element={<ErrorBoundary fallback="Error Ouccer"> <RemainBalance /> </ErrorBoundary>} />


                        </Route>
                        {/* <Route path='reports' element={<Reports />} >

                    </Route> */}


                        {/* Announcements */}
                        <Route path='announcements' element={<AuthGuard page="ANNOUNCEMENTS" read={true} ><ErrorBoundary fallback="Error Ouccer"> <Announcements /> </ErrorBoundary></AuthGuard>} />
                        <Route path='announcements/add' element={<AuthGuard page="ANNOUNCEMENTS" write={true} ><ErrorBoundary fallback="Error Ouccer"> <AddAnnouncement /> </ErrorBoundary></AuthGuard>} />
                        <Route path='announcements/:id' element={<AuthGuard page="ANNOUNCEMENTS" write={true} ><ErrorBoundary fallback="Error Ouccer"> <EditAnnouncement /> </ErrorBoundary></AuthGuard>} />


                        <Route path='payments-edit/:paymentId' element={<ErrorBoundary fallback="Error Ouccer"><EditPayment /></ErrorBoundary>} />
                        <Route path='payments-list/:payId' element={<AuthGuard page="PATIENT" read={true} ><ErrorBoundary fallback="Error Ouccer"><Payment /></ErrorBoundary></AuthGuard>} />
                        <Route path='payments-list' element={<ErrorBoundary fallback="Error Ouccer"><PaymentList /></ErrorBoundary>} />
                        <Route path='payments-list/reconcile/:referenceId' element={<AuthGuard page="PATIENT" read={true} ><ErrorBoundary fallback="Error Ouccer"><PaymentReconcile /></ErrorBoundary></AuthGuard>} />
                        <Route path='payments-list/reconcile/view/:referenceId' element={<AuthGuard page="PATIENT" read={true} > <ErrorBoundary fallback="Error Ouccer"><PaymentReconcileViewPage /></ErrorBoundary></AuthGuard>} />

                        {/* Setting */}
                        <Route path='settings' element={<ErrorBoundary fallback="Error Ouccer"> <Setting /> </ErrorBoundary>} >
                            <Route path='insurance' element={<ErrorBoundary fallback="Error Ouccer"> <InsuranceS /> </ErrorBoundary>} />
                            <Route path='cpt' element={<ErrorBoundary fallback="Error Ouccer"> <CPTList /> </ErrorBoundary>} />
                            <Route path='icd' element={<ErrorBoundary fallback="Error Ouccer"> <ICDList /> </ErrorBoundary>} />
                            <Route path='modifier' element={<ErrorBoundary fallback="Error Ouccer"> <ModifierList /> </ErrorBoundary>} />

                        </Route>

                        {/*ProblemLog section  */}
                        <Route path='problem-log' element={
                            // <AuthGuard page="ANNOUNCEMENTS" read={true} >
                            <ErrorBoundary fallback="Error Ouccer">
                                <ProblemLog />
                            </ErrorBoundary>
                            // </AuthGuard> 
                        } />
                        <Route path='create-problem-log/:problemId' element={<ErrorBoundary fallback="Error Ouccer"><CreateProblemLog /></ErrorBoundary>} />
                        {/* Help Section */}

                        <Route path='help-list' element={<AuthGuard page="ANNOUNCEMENTS" read={true} > <ErrorBoundary fallback="Error Ouccer"><HelpDeskList /> </ErrorBoundary></AuthGuard>} />
                        <Route path='help' element={<AuthGuard page="ANNOUNCEMENTS" write={true} > <ErrorBoundary fallback="Error Ouccer"><AddHelpDesk /> </ErrorBoundary></AuthGuard>} />

                        {/* Roles And Permissions */}
                        <Route path='role-and-permissions' element={<AuthGuard page="ROLES & PERMISSIONS" read={true} >  <ErrorBoundary fallback="Error Ouccer"><RoleandPermissions /> </ErrorBoundary></AuthGuard>} />
                        <Route path='update-role-and-permissions' element={<AuthGuard page="ROLES & PERMISSIONS" write={true} >  <ErrorBoundary fallback="Error Ouccer"><UpdateRoleAndPermission /> </ErrorBoundary> </AuthGuard>} />

                        <Route path='settings/insurance/add' element={<ErrorBoundary fallback="Error Ouccer"> <AddInsurance /> </ErrorBoundary>} />
                        <Route path='settings/insurance/:id' element={<ErrorBoundary fallback="Error Ouccer"> <EditInsurance /> </ErrorBoundary>} />
                        <Route path='settings/skill' element={<ErrorBoundary fallback="Error Ouccer"> <Skills /> </ErrorBoundary>} />

                        <Route path='settings/cpt/add' element={<ErrorBoundary fallback="Error Ouccer"> <AddCPT /> </ErrorBoundary>} />
                        <Route path='settings/cpt/:id' element={<ErrorBoundary fallback="Error Ouccer"> <EditCPT /> </ErrorBoundary>} />
                        <Route path='settings/icd/add' element={<ErrorBoundary fallback="Error Ouccer"> <AddICD /> </ErrorBoundary>} />
                        <Route path='settings/icd/:id' element={<ErrorBoundary fallback="Error Ouccer"> <EditICD /> </ErrorBoundary>} />
                        <Route path='settings/modifier/add' element={<ErrorBoundary fallback="Error Ouccer"> <AddModifier /> </ErrorBoundary>} />
                        <Route path='settings/modifier/:id' element={<ErrorBoundary fallback="Error Ouccer"> <EditModifier /> </ErrorBoundary>} />

                    </Route>)}
                <Route path={"/*"} element={<Page404 />} />
            </Routes>
        </Suspense>
        </>
    )
}

export default AllRoutes